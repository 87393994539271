@media screen and (max-width: 1000px) {
    .buy-modal {
        padding: 1rem;
    }

    .percentage-sell-button {
        width: 85px!important;
    }

    .amount-container {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
        display: flex;
        flex-direction: column;
        padding-top: 0rem!important;
        padding-bottom: 3rem;
        align-items: center;
        justify-content: center;
        height: 80%;
    }

    .order-container {
        padding: 1rem;
        height: 80%;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }

    .validation-order-container {
        height: 80%;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }
}

.buy-modal {
    display: flex;
}

.buy-modal .amount-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    width: 128px;
}

.buy-modal .order-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    width: 200px;
}

.buy-modal .amount-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    width: 128px;
}

.buy-modal h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #503bda;
    margin: 0;
}

.buy-modal h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin: 0;
}

.amount-container {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.amount-container-card {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.amount-container-card p {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #4a4a4a;
    margin-top: 3rem;
}

.amount-container  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #4a4a4a;
    margin-top: 3rem;
}

.amount {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #4a4a4a;
}

.amount-input {
    width: 60%;
    border: none;
}

.padding-btn {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.order-container {
}

.order-container p {
    margin: 0;
    margin-top: 12px;
}

.order__description {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #9e9c9c;
}

.order__value {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: right;
    color: #4a4a4a;
}

.justify-space {
    justify-content: space-between;
}

.order__total-coin {
    /* background-color: #f3ec78;
    background-image: linear-gradient(45deg, #58bff5, #5048dc); */
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    text-align: center;
}

.validation-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-align: center;
    color: #9e9c9c;
}

.validation-order-container p {
    margin: 1rem;
}

@media screen and (max-width: 1000px) {
    .dashboard {
        padding: 1rem;
    }

    .flex-column-responsive {
        flex-direction: column-reverse;
    }

    .information-container .custom-btn {
        font-size: 13px!important;
    }
}

.dashboard {
    width: 100%;
    height: 100%;
    background-color: #F7F7FD;
    overflow: hidden;
}
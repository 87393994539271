@media screen and (max-width: 1000px) {
    .wallet-page {
        padding: 0rem!important;
    }
    .summary-wallet .coin-wallet {
        width: auto!important;
    }
    .wallet-container {
        padding: 1rem!important;
    }
}

.wallet-page {
    /* padding: 2rem; */
    width: 100%;
}

.wallet-container {
    padding: 2rem;
}

.wallet-page h1 {
    margin-top: 0;
}
@media screen and (max-width: 1000px) {
    .container-img-signup {
        display: none;
    }

    .personal-input-signup {
        width: 100% !important;
    }

    .container-personal-input {
        flex-direction: column !important;
    }

    .width-signin {
        width: 90% !important;
    }

    .signup__handcontainer img {
        position: absolute;
        bottom: 150px;
        right: -140px;
        width: 120%;
    }

    .slidein {
        animation-duration: 3s;
        animation-name: slideinmobile !important;
    }

    .form-container {
        width: 100%!important;
    }

    .nav-logo-mobile {
        display: block!important;
    }

    .signup {
        height: 800px;
    }
}

.signup {
    width: 100%;
}

.nav-logo-mobile {
    display: none;
}

.form-container {
    width: 70%;
}

.img-background-sign-in {
    position: absolute;
    width: 100%;
    height: 100%;
}
.img-background-sign-in img {
    width: 100%;
    height: 100%;
}

.blue-text-signin {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-decoration-line: underline;
    color: #59c8f7;
    cursor: pointer;
    opacity: 0.6;
}

.black-text-signin {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    margin: 0;
}

.custom-btn:disabled {
    opacity: 0.2;
}

.validation-news {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    background: linear-gradient(253.64deg, #abf9a7 6.24%, #59c8f7 105.74%);
    margin-bottom: 0px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

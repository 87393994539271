@media screen and (max-width: 1000px) {
    .summary {
        margin-top: 1rem;
        width: 100% !important;
    }

    .no-crypto-dashboard {
        margin-top: 1rem;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
        height: 200px!important;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }
}

.summary {
    margin-top: 1rem;
    width: 40%;
}

.summary .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.summary .box-wallet {
    background: #ffffff;
    border-radius: 10px;
    padding: 1rem;
    min-width: 314px;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
    margin-top: 1rem;
    overflow: hidden;
}

.box-wallet h1 {
    margin: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #503bda;
}

.box-wallet h2 {
    margin: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.box-wallet .value {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    color: #503bda;
    text-align: end;
    margin-bottom: 0;
}

.box-wallet .nbrofcurrency {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #5b5c70;
    text-align: end;
    margin: 0;
}

.box-wallet .wallet-gestion {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(80, 59, 218, 0.5);
    text-align: end;
    margin: 0;
    margin-top: 0.2rem;
}

.current-asset-price {
    position: absolute;
    right: 0;
    top: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #5b5c70;
    margin: 0;
}

.no-crypto {
    margin-top: 3rem;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
    height: 200px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}


.add-button {
    /* position: absolute; */
    top: 1px;
    left: 1px;
}

.no-crypto-dashboard {
    margin-top: 1rem;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
    height: 54vh;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

.info-description {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    background-color: white;
    margin: 0;
}

.container-identity {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1000px) {

    .coin-page {
        padding: 0rem 1rem!important;
        width: 100%;
    }

    .coin-wallet-graph {
        width: 100%!important;
    }

    .solde-wallet {
        font-size: 15px!important;
    }

    .responsivewalletbutton .sell-button {
        font-size: 17px!important;
        width: 115px!important;
        height: 35px!important;
    }

    .solde-card-wallet {
        height: 53px!important;
    }

    .responsivewalletbutton .buy-button {
        font-size: 17px!important;
        width: 115px!important;
        height: 35px!important;
    }
}

.coin-page {
    padding: 0rem 3rem;
    width: 100%;
}

.coin-wallet-graph {
    background: #ffffff;
    border-radius: 10px;
    padding: 1rem;
    width: 60%;
    overflow: hidden;
}

.title-coin {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #503bda;
    margin-bottom: 0;
}

.coinname {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-top: 0;
}

.solde-card-wallet {
    margin-top: 1rem;
    height: 75px;
    background: #ffffff;
    border-radius: 10px;
    padding-right: 2rem;
    padding-left: 2rem;
}

.solde-wallet {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #4a4a4a;
}

.history-coin-container {
    background: #ffffff;
    border-radius: 10px;
    margin-top: 1rem;
    padding: 1rem;
}

.history-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}

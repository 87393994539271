.activity-card {
    background-color: white;
    padding-right: 1rem;
    padding-left: 1rem;
    border-radius: 5px;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
    margin-top: 1rem;
}

.activity-card h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 0;
}

.activity-card h2 {
    margin: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}

.activity-card .date {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    color: #969696;
}

.activity-card .status {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #5b5c70;
}

.status span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #abf9a7;
    margin-left: 1rem;
}

@media screen and (max-width: 1000px) {
    .banktransfer__modal {
        padding: 1rem;
    }
}

.banktransfer__modal {
}

.banktransfer p {
    font-family: Roboto;
    font-style: normal;
    font-size: 14px;
    color: #4a4a4a;
}

.banktransfer__container p {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.justify-between {
    justify-content: space-between;
}

.ref-bank {
    width: 155px;
}

.buy-sell-selector-container {
    width: 100%;
}

.buy-sell-selector {
    background-color: #EFEFEF;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #acacac;
    text-align: center;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
}

.buy-sell-selector-selected {
    background-color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    text-align: center;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
}

.buysell-component {
    width: 35%;
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
}

.sidebar {
    width: 240px;
    padding-right: 1rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    text-align: center;
    height: auto;
    min-height: 100vh;
    background-color: white;
}

.sidebar__route {
    /* width: 100%; */
    width: 208px;
    padding: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4d4d4d;
}

.sidebar__route:hover {
    background: rgba(89, 200, 247, 0.2);
    border-radius: 5px;
}

.sidebar .logo {
    margin-right: 0.5rem;
    width: 172px;
    height: 18px;
}

.sidebar__route img {
    margin-right: 0.5rem;
}

.sidebar .container-logo {
    padding-top: 3rem;
    padding-bottom: 2rem;
}

.user-card {
    margin-top: 2rem;
    background-color: #503BDA;
    height: 140px;
    border-radius: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: white;
    position: relative;
}

.user-card-prenium {
    margin-top: 2rem;
    background-color: rgb(76, 76, 77);
    height: 140px;
    border-radius: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: white;
    position: relative;
}

.user-card-prenium p {
    text-align: initial;
}

.user-card p {
    text-align: initial;
}
.testdeouf {
    color: red;
    background-color: red;
}


.navbar-app {
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    z-index: 1000;
    height: 65px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    box-shadow: 0px 4px 19px rgb(0 0 0 / 8%);
}
@media screen and (max-width: 1000px) {
    .img-windowimg-container {
        display: none!important;
    }

    .container-comingsoon {
        width: 100%!important;
    }
}


.comingpage h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    text-align: center;
    color: #000000;
}

.comingpage p {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    color: #000000;
}

.comingpage {
    position: relative;
}

.comingpage .windowimg {
    position: absolute;
    right: 13%;
    top: 17%;
}
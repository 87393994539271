.activity-container {
    background-color: white;
    height: 100%;
}

.activity-container .title {
    font-family: Roboto;
    font-style: normal;
    padding: 1rem;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    width: 70px;
}

.watch-more-activity {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: end;
    text-decoration-line: underline;
    color: #0095d4;
    cursor: pointer;
}


.activity-none {
    display: none;
}
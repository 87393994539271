@media screen and (max-width: 1000px) {
    .fiat-container {
        width: 100%;
        height: 100%;
        background-color: white;
    }
    
    .fiat-responsive-container {
        padding: 1rem;
        box-shadow: 0px 4px 19px rgb(0 0 0 / 8%);
        border-radius: 10px;
    }
}

.fiat-container {
    width: 100%;
    height: 100%;
}

.fiat-container p {
    margin: 0;
}

.fiat-container h1 {
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    color: #000000;
}

.button-fiat {
    color: white;
    background: linear-gradient(264.76deg, #59c8f7 -2.02%, #abf9a7 132.47%), #503bda;
    border-radius: 5px;
    height: 25px;
    width: 79px;
    cursor: pointer;
    border: none;
}

.percentage-sell-button {
    border: 0px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #4a4a4a;
    cursor: pointer;
    border-radius: 7%;
    width: 100px;
}

.percentage-sell-button:hover {
    background: linear-gradient(89.93deg, #59c8f7 36.25%, #abf9a7 99.92%);
}

@media screen and (max-width: 1000px) {
    .subscription {
        width: auto!important;
        padding: 2rem;
        border-radius: 5px;
        color: white;
        margin-bottom: 3rem;
    }
    .container-sub {
        flex-direction: column!important;
        padding: 1rem!important;
    }
    
}

.subscription {
    width: 38%;
    padding: 2rem;
    border-radius: 5px;
    color: white;
}

.container-sub {
    padding: 2rem 3rem
}

.subscription h2 {
    margin-top: 0;
}

.subscription span {
    color: white;
}

.current-subscription-btn {
    background-color: transparent;
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.2px;
    color: #ffffff;
    border: solid 1px white;
    padding: 1rem 2rem;
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
}

.subscription-btn {
    background-color: transparent;
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.2px;
    color: #ffffff;
    background: linear-gradient(90.27deg, #59C8F7 47.76%, #ABF9A7 99.85%);
    padding: 1rem 2rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
}


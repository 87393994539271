@media screen and (max-width: 1000px) {
    .deposit {
        padding: 1rem;
    }
    .close-img-modal {
        top: 7px!important;
        right: 7px!important;
    }
}


.close-img-modal {
    position: absolute;
    right: 0;
    cursor: pointer;
}

.deposit {
}

.deposit-container:hover {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    height: 187px;
    cursor: pointer;
    background: linear-gradient(90.27deg, rgba(89, 200, 247, 0.3) 47.76%, rgba(171, 249, 167, 0.3) 99.85%);
}

.deposit-container {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    height: 187px;
    cursor: pointer;
}

.deposit h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #4a4a4a;
}

.deposit__subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #4a4a4a;
    margin: 0;
    margin-bottom: 0.5rem;
}

.deposit span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    color: #b6b4b4;
}

.deposit__limit-container {
    padding: 1rem;
    background: linear-gradient(90.27deg, #59c8f7 47.76%, #abf9a7 99.85%);
    border-radius: 7px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
}


.deposit__limit-container p {
    margin: 0;
}

.deposit__limit-container h3 {
    margin: 0;
}
.signup__img-container {
    overflow: hidden;
    height: 100vh;
}

.input-module-border {
    padding: 1rem;
    position: relative;
    background: linear-gradient(0.27deg, #59c8f7 47.76%, #abf9a7 99.85%);
    opacity: 0.5;
    padding: 3px;
    border-radius: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.input-module {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: none;
    background: rgba(243, 243, 243);
}

.signup span {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.signup h1 {
    width: 192px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: #000000;
}

.signup__handcontainer {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.signup__handcontainer img {
    position: absolute;
    bottom: 0;
    right: 0px;
}

.slidein {
    animation-duration: 3s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        bottom: -300px;
        right: -300px;
    }

    to {
        bottom: 0px;
        right: 0px;
    }
}

@keyframes slideinmobile {
    from {
        bottom: -300px;
        right: -300px;
    }

    to {
        bottom: 150px;
        right: -140px;
    }
}

@media screen and (max-width: 1000px) {
    .coin-card {
        padding-bottom: 1rem;
    }
}

.coin-card {
    background: white;
    box-shadow: 0px 4px 19px rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.coin-card h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #1a1a1a;
    margin: 0;
}

.coin-card h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #1a1a1a;
    margin: 0;
}

.coin-card .price {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    color: #503bda;
}

.buy-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #ffffff;
    border: none;
    background: linear-gradient(270deg, #503bda 0%, #59c8f7 100%), #503bda;
    border-radius: 5px;
    height: 25px;
    width: 79px;
    cursor: pointer;
}


.buy-button:disabled,
.buy-button[disabled] {
    background: #666666!important;
}


.sell-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #ffffff;
    border: none;
    background: linear-gradient(270deg, #503bda 0%, #59c8f7 100%), #503bda;
    border-radius: 5px;
    height: 25px;
    width: 79px;
    cursor: pointer;
}


.sell-button:disabled,
.sell-button[disabled] {
    background: #666666!important;
}
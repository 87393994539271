.user-card-name {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    margin: 0;
    color: #ffffff;
    margin-top: 13px;
}

.user-card-name strong {
    font-weight: bold;
}

.custom-tooltip {
    border: solid 1px #59C8F7;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
}

.custom-tooltip label {
    margin: 0!important;
    padding: 0!important;
}
@media screen and (max-width: 1000px) {
    .coinlist {
        width: 100%!important;
    }
}


.coinlist .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.coinlist {
    width: 55%;
    margin-top: 1rem;
}

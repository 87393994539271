.card-container {
    border: solid 0.5px#c4c4c4;
    border-radius: 5px;
    padding: 1rem 0.3rem;
}

.input-card {
    margin-top: 0.3rem;
    border: solid 0.5px#c4c4c4;
    border-radius: 5px;
    padding: 1rem 0.3rem;
}

.input-card::placeholder {
    color: rgb(66, 71, 112, 0.5);
    font-size: 16px;
}

.input-card:focus {
    border: solid 0.5px #503BDA;
}
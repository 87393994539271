@media screen and (max-width: 1000px) {
    .container-kyc-info {
        display: flex;
        width: 100%;
        justify-content: space-around;
        flex-direction: column;
    }    
}

.input-description {
    font-size: 13px;
    padding-left: 0.3rem;
}

.container-kyc-info .input-module-border {
    margin-top: 0.3rem!important;
}

.container-kyc-info {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.kyc-header h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
}

.kyc-header p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.Modal {
    position: absolute;
    background-color: white;
    padding: 1rem;
    border-radius: 20px;
    width: 837px;
    height: 598px;
}

.title-document-kyc {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #4a4a4a;
}

.text-document-kyc {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #4a4a4a;
}

*:focus {
    outline: 0;
}

.upload-zone {
    cursor: pointer;
}

.validation-kyc {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #000000;
}

@media screen and (max-width: 1000px) {
    .verification-container {

        padding: 1rem 2rem!important;
        flex-direction: column!important;
    }

    .verification-lvl-card {
        width: auto!important;
        margin-bottom: 3rem;
    }
}

.title-verification {
    margin: 0;
    margin-left: 1rem;
    margin-top: 2rem;
    font-size: 22px;
}

.verification-container {
    padding: 2rem 5rem;
    justify-content: space-around;
}

.verification-lvl-card {
    background: #ffffff;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    width: 43%;
    padding: 2rem;
}

.verification-lvl-card h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
}

.verification-lvl-card p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;
}

.verif-step {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}


.btn-kyc-waiting {
    border: 1px solid #ffba08;
    color: #ffba08;
    border-radius: 7px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}


.btn-kyc-validate {
    border: 1px solid #2b9348;
    color: #2b9348;
    border-radius: 7px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
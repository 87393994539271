@media screen and (max-width: 1000px) {
    .summary-wallet .coin-wallet {
        width: auto!important;
    }
}

.summary-wallet .coin-wallet {
    background: #ffffff;
    border-radius: 10px;
    padding: 1rem;
    /* width: 98%; */
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
    margin-top: 1rem;
    overflow: hidden;
}

.coin-wallet h1 {
    margin: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #503bda;
}

.coin-wallet h2 {
    margin: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}

.coin-wallet .value {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    color: #503bda;
    text-align: end;
    margin-bottom: 0;
}

.coin-wallet .nbrofcurrency {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #5b5c70;
    text-align: end;
    margin: 0;
}

.coin-wallet .wallet-gestion {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(80, 59, 218, 0.5);
    text-align: end;
    margin: 0;
    margin-top: 0.2rem;
}

.align-price-wallet {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 80%;
}

.button-wallet-gestion {
    padding: 1rem;
    color: white;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}

.button-wallet-gestion:disabled,
.button-wallet-gestion[disabled] {
    background: #666666!important;
}

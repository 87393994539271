@media screen and (max-width: 1000px) {
    .current-asset-price-market {
        left: 9rem!important;
    }

    .marketplace-container {
        padding: 1rem!important;
    }

    .button-market-gestion {
        padding: 0.5rem 1rem;
        width: 100px!important;
        /* font-size: 12px; */
        height: 50px;
        color: white;
        border: 0;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 0.5rem;
    }
}

.button-market-gestion {
    padding: 0.5rem 1rem;
    width: 150px;
    color: white;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0.5rem;
}

.button-market-gestion:hover {
    opacity: 0.8;
}

.marketplace-card {
    background: white;
    box-shadow: 0px 4px 19px rgb(0 0 0 / 8%);
    border-radius: 10px;
    margin-top: 1rem;
}

.current-asset-price-market {
    position: absolute;
    left: 11%;
    top: 1rem;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #5b5c70;
    margin: 0;
}
@media screen and (max-width: 1000px) {
    .activity-page {
        width: 100%;
        padding: 1rem!important;
    }

    .activity-filter-node {
        width: 100%!important;
    }

    .activity-filter-node-select {
        width: 100%!important;
    }

    .activity-card {
        padding-bottom: 1rem;
    }
}

.download-btn {
    background: linear-gradient(90.27deg, #59C8F7 47.76%, #ABF9A7 99.85%);
    padding: 0.5rem;
    border-radius: 10px;
}

.download-btn a {
    color: white!important;
    font-weight: 700;
}

.activity-page {
    width: 100%;
    padding: 2rem;
}

.activity-filter-node {
    border: 2px solid #503bda;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: white;
    background: #503bda;
    width: 10%;
    text-align: center;
    margin-right: 1rem;
    margin-left: 1rem;
    cursor: pointer;
    padding: 10px;
}


.activity-filter-node-select {
    border: 2px solid #503bda;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: white;
    background: linear-gradient(264.76deg, #59c8f7 -2.02%, #abf9a7 132.47%), #503bda;
    width: 10%;
    text-align: center;
    margin-right: 1rem;
    margin-left: 1rem;
    cursor: pointer;
    padding: 10px;
}

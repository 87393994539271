@media screen and (max-width: 1000px) {
    .information-container {
        width: auto !important;
        padding-top: 1rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .padding-setting {
        padding: 0rem!important;
        width: 100%;
    }

    .setting-container .title-section-selected {
        padding: 1rem!important;
    }

    .setting-container .title-section {
        padding: 1rem!important;
    }
}

.setting-container {
    overflow: hidden;
    background-color: white;
    margin-top: 2rem;
    border-radius: 1rem;
}


.padding-setting {
    padding: 2rem;
    width: 100%;
}

.setting-container .title-section {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    padding: 2rem;
    margin: 0;
}

.setting-container .title-section-selected {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    padding: 2rem;
    background-color: white;
    margin: 0;
}

.title-section-container {
    background: #efefef;
}

.information-container {
    width: 70%;
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
}

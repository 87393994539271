@media screen and (max-width: 1000px) {
    .evolution__responsive-container {
        padding: 1rem;
        margin-top: 1rem;
        background: white;
        border-radius: 10px;
        box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
    }
}

.evolution {
    height: 210px;
    background: white;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 1rem;
}

.evolution__container {
    position: relative;
    width: 70%;
}

.evolution__container p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #232323;
    margin: 0;
    margin-bottom: 1rem;
}

.evolution__stats-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.evolution__stats {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #212121;
    margin: 0;
}

.evolution_percentage {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #028900;
}

.evolution-number {
    position: absolute;
    z-index: 3;
}

